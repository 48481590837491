import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Checkup from "../../components/checkup"
import { Link } from "gatsby"
import customer from "../../assets/img/emre-altan.jpg"
import mockup from "../../assets/img/arcadium-mockup.png"
import eelbeyglu from "../../assets/img/teams/11.jpg"
import serife from "../../assets/img/teams/20.jpg"
import tugce from "../../assets/img/teams/03.png"
import simay from "../../assets/img/teams/08.jpg"
import video from "../../assets/img/arcadium-anneler-gunu.mp4"
import onay from "../../assets/img/teams/23.jpg"
import OtherCaseStudy from "../../components/other-case"




const ReferancesDetail = () => (
    <Layout>
        <SEO title="Arcadium Success Story" />
        <div className="referances-detail py60 container">
            <div className="col-md-6 detail-left">
                <b>BAŞARI HİKAYESİ</b>
                <h1>Arcadium</h1>
                <p>Ankara’nın gözde semtlerinden Çayyolu’nda yerel bir alışveriş merkezi olan Arcadium AVM için Aralık 2016’dan beri sosyal medya yönetimi ve blog hizmeti sunuyoruz. Buna göre ilk olarak Arcadium AVM’nin sosyal mecralardaki mevcut hedef kitlesine uygun bir strateji belirlendi. </p>
                <p>Facebook, Instagram, Twitter, YouTube ortamlarında @arcadiumavm kullanıcı adıyla yer alan firma, bugüne kadar yapılan çalışmalarla takipçi sayısında ve erişim grafiklerinde büyük artış yaşamış oldu.</p>
                <p>Arcadium AVM’nin Facebook’taki takipçi sayısında %86’lık bir artış gözlemlenirken Instagram’daki takipçi sayısı ise 7 katına çıktı.</p>
                <p>Yapılan operasyonlar sonrasında Arcadium AVM, sosyal medya platformlarında aylık ortalama 800.000 kişiye erişim sağlayabilir hale geldi.</p>

            </div>
            <div className="col-md-6 referances-boxs">
                <div className="boxs">
                    <div className="box">
                        <span>%172</span>
                        <p>2 yıllık içerisindeki
ciro artışı</p>
                    </div>
                    <div className="box">
                        <span>%150</span>
                        <p>2 yıllık organik trafik<br />artışı.</p>
                    </div>

                </div>
                <div className="provided">
                    <h3>Verilen Hizmetler</h3>
                    <div className="provided-box">
                        <div className="box">
                            <Link to="/search-engine-optimization/"></Link>
                            <svg style={{ marginLeft: -7 }} xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 79 80">
                                <g id="Icons-_-Shopping-_-Chart-line_1" data-name="Icons-/-Shopping-/-Chart-line#1" transform="translate(-0.462 0.373)">
                                    <rect id="bound" width="79" height="80" transform="translate(0.462 -0.373)" fill="none" />
                                    <path id="Path-95" d="M9.6,55.773H59.071a3.3,3.3,0,0,1,0,6.6H6.3a3.3,3.3,0,0,1-3.3-3.3V6.3a3.3,3.3,0,0,1,6.6,0Z" transform="translate(6.895 7.094)" fill="#000000" />
                                    <path id="Path-97" d="M12.7,33.493a3.3,3.3,0,0,1-4.812-4.512L20.261,15.788a3.3,3.3,0,0,1,4.547-.253l9.762,8.33L47.289,7.755a3.3,3.3,0,1,1,5.178,4.088l-14.842,18.8a3.3,3.3,0,0,1-4.73.465L22.92,22.6Z" transform="translate(16.088 15.138)" fill="#000000" opacity="0.3" />
                                </g>
                            </svg>
                            SEO
                        </div>
                        <div className="box">
                            <Link to="/social-media/"></Link>
                            <svg id="instagram-logo" xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 61.312 61.312">
                                <path id="Path_36346" data-name="Path 36346" d="M44.392,0H16.92A16.939,16.939,0,0,0,0,16.92V44.392a16.939,16.939,0,0,0,16.92,16.92H44.392a16.939,16.939,0,0,0,16.92-16.92V16.92A16.939,16.939,0,0,0,44.392,0ZM55.872,44.392a11.493,11.493,0,0,1-11.48,11.48H16.92A11.493,11.493,0,0,1,5.44,44.392V16.92A11.493,11.493,0,0,1,16.92,5.44H44.392a11.493,11.493,0,0,1,11.48,11.48V44.392Z" />
                                <path id="Path_36347" data-name="Path 36347" d="M56.767,40.97a15.8,15.8,0,1,0,15.8,15.8A15.816,15.816,0,0,0,56.767,40.97Zm0,26.156A10.358,10.358,0,1,1,67.125,56.768,10.37,10.37,0,0,1,56.767,67.126Z" transform="translate(-26.111 -26.112)" />
                                <path id="Path_36348" data-name="Path 36348" d="M122.91,28.251a3.987,3.987,0,1,0,2.821,1.168A4,4,0,0,0,122.91,28.251Z" transform="translate(-75.793 -18.005)" />
                            </svg>
                            Sosyal Medya
                        </div>
                    </div>
                </div>
                <div className="project-details">
                    <h3>Künye</h3>
                    <div className="project-details_list">
                        <ul>
                            <li>Marka Yöneticisi:</li>
                            <li>Dijital Pazarlama Stratejist:</li>
                            <li>Google Ads:</li>
                            <li>SEO:</li>
                            <li>Website Geliştirme:</li>
                        </ul>
                        <ul>
                            <li><img src={simay} />Simay Akkurt</li>
                            <li><img src={eelbeyglu} alt="Alaattin Emre Elbeyoğlu" />Emre Elbeyoğlu</li>
                            <li><img src={serife} alt="Şerife Aytekin" />Şerife Aytekin</li>
                            <li><img src={tugce} alt="Tuğçe Gör" />Tuğçe Gör</li>
                            <li><img src={onay} alt="Barış Onay" />Barış Onay</li>
                        </ul>
                    </div>

                </div>
            </div>
        </div>
        <div className="container">
            <div className="testimonial-customer" id="one">
                <p>“Sosyal medya trendlerini takip eden ajansımız sayesinde müşterilerimizin gündemini yakalayabiliyor ve onların ilgisini çekecek projelere imza atıyoruz.”</p>
                <div className="author">
                    <div>
                        <img src={customer}></img>
                    </div>
                    <div className="author-name">
                        Emre Altan<br />
                        <span>CEO</span>
                    </div>
                </div>
            </div>




            <div className="customer-content pt60">
                <h2>4 yılda %650 organik trafik artışı.</h2>

                <p>“Life-style” içerikler ön planda tutularak oluşturulan konsept görsellere ve marka tanıtım içeriklerine yer verildi. “Hayatın İçinden”, ” Arcadium Keşif” ve “Arcadium Detay” adı verilen konseptler, en başarılı olanlara örnek olarak verilebilir.</p>
                <p>Erişim ve etkileşimi artırmak amacıyla “Arcadium Haftanın Hediyesi” başlığında Facebook, Twitter ve Instagram üzerinden “Anında Kazan” yarışmaları düzenlendi. Takipçiler tarafından büyük ilgi gören “Anında Kazan” yarışmaları farklı başlıklarla devam ettirildi.</p>
                <h2>Arcadium Blog</h2>
                <p>Web sitesi içerisinde “Life-style” içerik stratejisine uygun olarak bir blog sayfası oluşturuldu. Ayda 4 içeriğin yayınlandığı blog, halihazırda ortalama 5.000 kişi tarafından ziyaret ediliyor. Diğer yandan bir içeriğin ortalama 2000 okunma sayısına sahip olduğunu da söylemek mümkün.</p>

                <div className="site-preview" style={{ width: '100%', margin: '0px auto' }}>
                    <div style={{ width: '100%' }} className="mockup">
                        <img src={mockup} />
                    </div>
                </div>
                <h2>Anneler Günü Kampanyası</h2>
                <p>Mayıs 2016’da Anneler Günü için dijital platformda başlayan projemiz, AVM içerisinde gerçekleştirilen etkinlikler ile devam ettirildi. “Anında Kazan” yarışmalarına alışkın olan takipçilere de Facebook üzerinden“Sürpriz Hediye” başlığı altında Paylaş-Kazan yarışması düzenlendi.</p>
                <p>“Bir sinema salonu senin olsa Anneler Günü’nü kutlamak için ona neler söylerdin?”</p>
                <p>Takipçiler arasından seçilen 10 kişiden, anneleri için kısa bir video hazırlamaları istendi. Kendilerine sinema bileti hediye edildiğini öğrenen anneler ise Arcadium Sinemaları’nda aynı seansta buluştular. Sinema perdesinde bir anda çocuklarının Anneler Günü videoları ile karşılaşan annelerin gülen gözleri ve şaşkınlıkları görülmeye değerdi.</p>
                <p>Sinema salonunda bulunan gizli kamera görüntüleri ve Facebook canlı yayını ile desteklenen proje, takipçiler tarafından büyük beğeniyle karşılandı.</p>
                <p>Video; YouTube, Instagram ve Facebook üzerinden toplam 800.000 izlenme oranına ulaştı.</p>

                <video width="70%" controls style={{ margin: '30px auto' }}>
                    <source src={video} type="video/mp4" />
                </video>

                <h2>Arcadium Güzellik Günleri Vlog Projesi</h2>
                <p>Ağustos 2017’de ünlü makyaj Vlogger’ı @ojemrujumrimelim ile birlikte “200 TL ile Makyaj Çantası Oluşturma” başlığı altında bir video içerik hazırlandı. Video, müşterileriyle Arcadium AVM içerisinde buluşan kozmetik markaları ile birlikte hayata geçirildi. Projeye paralel olarak “Arcadium Güzellik Günleri” etkinliği düzenlendi ve ünlü vlogger’lar ile birlikte keyifli bir söyleşi gerçekleştirildi.</p>
            </div>

        </div>


        <OtherCaseStudy />

        <Checkup />
    </Layout >
)

export default ReferancesDetail
